import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';

import Select from "react-select";
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare,faSpinner} from '@fortawesome/free-solid-svg-icons';
import { setActiveAuditoria, startSaveAuditoria } from '../../../store/auditorias';
import usePrivateApi from '../../../hooks/usePrivateApi';
import moment from 'moment';


export const FormularioAuditoria = ( ) => {
    const { api } = usePrivateApi();
    const dispatch = useDispatch();

    const [submitInCourse, setSubmitInCourse] = useState(false);
    const {messageSaved, auditoriaActiva} = useSelector(state => state.auditorias);
    const {farmacias=[]} = useSelector(state => state.farmacias);
    const {laboratorios=[]} = useSelector(state => state.laboratorios);
   
    const { register, handleSubmit, formState: { errors }, control,setValue} = useForm();

    useEffect(() => {
        setValue('descripcion',auditoriaActiva.descripcion);
        setValue('farmacia',{value:auditoriaActiva.farmacia.id,label:auditoriaActiva.farmacia.nombre});
        setValue('laboratorio',{value:auditoriaActiva.laboratorio.id,label:auditoriaActiva.laboratorio.nombre});
    }, [auditoriaActiva]);
    

    useEffect(() => {
        if(messageSaved.length > 0){
            Swal.fire('Auditoría Actualizada', messageSaved, 'success');
        }                  
    }, [messageSaved]);

    
    const onSubmit = values => {  
        const dataFormatted = {
            id:auditoriaActiva.id,
            farmacia:{...auditoriaActiva.farmacia, id:values.farmacia.value},
            laboratorio:{...auditoriaActiva.laboratorio,id:values.laboratorio.value},            
            descripcion:values.descripcion,            
            fechaInicio:moment().valueOf(),
            usuario:auditoriaActiva.usuario,
        }  
        
        dispatch(setActiveAuditoria(dataFormatted));        
        
        

        Swal.fire({
            title: '¿Guardar cambios sobre la auditoría?',  
            text: "",
            icon: 'warning',    
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Sí, guardar los cambios!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return dispatch(startSaveAuditoria({api}))                     
                .then(response => {                        
                  if (!response.ok) {
                     throw new Error(response.statusText)
                   }
                   return response.json();
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Requerimiento Fallido: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {        
              Swal.fire({
                title: `${result.value}`,          
              })
            }
          })
        


        
    };

const estadoBtnSubmit =(submitInCourse || !!auditoriaActiva.fechaFin) ? 'disabled':''; 
const estadoBtnCss = (submitInCourse || !!auditoriaActiva.fechaFin) ? 'cursor-not-allowed':'';

  return (
    <>
        <div className="flex min-h-full items-center justify-center px-4 py-2 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
                <div>   
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Auditoría</h2>
                    {auditoriaActiva && !!auditoriaActiva.id  ?
                            <dl className="mt-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                                <div key="1" className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{auditoriaActiva.usuario.nombreUsuario}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">Usuario</dd>
                                </div>

                                <div key="2" className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{auditoriaActiva?.productos?.length}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">Productos a auditar</dd>
                                </div>

                            </dl>  
                        : ''
                    }
                </div>
                <form onSubmit={handleSubmit(onSubmit)}
                      className="mt-8 space-y-6" 
                      id="idFormNuevaAuditoria">

                    <div className="-space-y-px rounded-md shadow-sm">
                        <div>
                            <label htmlFor="descripcion" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Descripción
                            </label> 
                            <input
                                type="Text"
                                name="descripcion"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                autoComplete="off"
                                //value={auditoriaActiva.descripcion}
                                placeholder='Ej// Pfizer Mayo 2023'
                                {...register("descripcion", 
                                                {required: "Descripcion es requerida",
                                                minLength: {value:4, message:'Debe tener al menos 4 caracteres'},
                                                maxLength: {value:30, message:'Máximo 30 caracteres'},
                                                })
                                    }
                            />
                            {<p className="text-red-600">{errors.descripcion?.message} </p>}                            
                        </div>
                            
                        <div>
                            <label htmlFor="farmacia" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Farmacias
                            </label>
                            <Controller
                                name="farmacia"
                                control={control}                                                              
                                rules={{ required: true }}                                                              
                                render={({ field }) => (
                                    <Select 
                                        {...field} 
                                        isDisabled={!!auditoriaActiva.id}                                        
                                        options={farmacias.map(({id,nombre})=>({value:id,label:nombre}))}                                         
                                    />
                                )}
                            />
                            {errors.farmacia && (<p className="text-red-600">Este campo es requerido</p>)}
                        </div> 

                        <div>
                            <label htmlFor="laboratorio" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                               Laboratorio
                            </label> 
                            <Controller
                                name="laboratorio"
                                control={control}                                 
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select 
                                        {...field} 
                                        isDisabled={!!auditoriaActiva.id}
                                        options={laboratorios.map(({id,nombre})=>({value:id,label:nombre}))} 
                                    />
                                )}
                            />
                            {errors.laboratorio && (<p className="text-red-600">Este campo es requerido</p>)}
                        </div>                      

                        <div>
                            <button type="submit"
                                className={`group ${estadoBtnCss} relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                disabled={estadoBtnSubmit} >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">                                
                                    {submitInCourse ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faPenToSquare} />}
                                </span>
                                {(submitInCourse) ? 'Actualizando...' : 'Guardar'}
                            </button>
                        </div>            

                    </div>

                </form>

            </div>
        </div>        
    </>                
            
       
  );

}
